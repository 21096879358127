import { hideOverlay, showOverlay, overlayCloseHandlers } from "../../overlay/overlay";
import { isThirdPartyHref } from "../embedding/embedding";
import { embedsAllowed, canUseEmbeds } from "../../privacy/privacy";

import "./location.css";

let hideLocationCallback: (() => void) | null = null;

const initLocation = (locationSection: HTMLElement) => {
	// Cache DOM elements
	const mapElement = locationSection.querySelector<HTMLElement>("lazy-iframe");
	const imageElement = locationSection.querySelector<HTMLElement>("location-image");
	const nextSection = locationSection.nextElementSibling;
	if (!nextSection || (!mapElement && !imageElement)) { return; }

	// Cache calculations
	const max = Math.min(window.innerWidth, window.innerHeight) * 0.86;
	const width = max * 0.79;
	const height = max * 0.79;

	// State variables
	let oldX = 0;
	let oldY = 0;

	// Create button once
	const locationButton = document.createElement("location-button");
	nextSection.append(locationButton);

	if (nextSection.querySelectorAll("help-video").length > 0) {
		locationButton.classList.add("offset");
	}

	const hideLocation = () => {
		requestAnimationFrame(() => {
			locationSection.style.cssText = `
                transition: left ease 600ms, top ease 400ms, width ease 500ms, height ease 500ms, border-radius ease 300ms;
                left: ${oldX}px;
                top: ${oldY}px;
                width: 2em;
                height: 2em;
                border-radius: 50%;
            `;

			setTimeout(() => {
				locationSection.style.zIndex = "0";
				if (mapElement) {
					destroyIframe(mapElement);
				}
				if (imageElement) {
					imageElement.style.display = "none";
				}
			}, 400);
		});

		hideLocationCallback = null;
	};

	const showLocation = async () => {
		if (!nextSection) { return; }

		if (hideLocationCallback) {
			hideOverlay();
			return;
		}

		// Cache position calculations
		const rect = locationButton.getBoundingClientRect();
		oldX = Math.floor(rect.left);
		oldY = Math.floor(rect.top + window.scrollY);

		// Batch style changes
		requestAnimationFrame(() => {
			locationSection.style.cssText = `
                overflow: hidden;
                left: ${oldX}px;
                top: ${oldY}px;
                position: absolute;
                z-index: 2001;
                width: 2em;
                height: 2em;
                border-radius: 50%;
                align-items: center;
            `;

			// Force reflow
			locationSection.offsetTop;

			requestAnimationFrame(() => {
				locationSection.style.cssText = `
                    transition: left ease 600ms, top ease 400ms, width ease 500ms, height ease 500ms, border-radius ease 700ms;
                    display: flex;
                    width: ${width}px;
                    height: ${height}px;
                    opacity: 1;
                    border-radius: 8px;
                    left: ${Math.floor(document.body.clientWidth/2 - width/2)}px;
                    top: ${window.scrollY + 100}px;
                `;
			});
		});

		showOverlay(locationSection);

		if (mapElement) {
			const source = mapElement.getAttribute("src");
			if (!source) { return; }

			// Check privacy settings once
			const canEmbed = embedsAllowed() && (!isThirdPartyHref(source) || await canUseEmbeds());

			if (!canEmbed) {
				if (isThirdPartyHref(source)) {
					mapElement.addEventListener("click", canUseEmbeds, { once: true });
					mapElement.classList.add("waiting-for-approval");
					locationSection.classList.add("awaiting-allow");
					await canUseEmbeds();
					mapElement.classList.remove("waiting-for-approval");
				} else {
					locationSection.style.display = "none";
					hideOverlay();
					return;
				}
			}

			locationSection.classList.remove("awaiting-allow");
			const loadedIframe = buildIframe(mapElement, width, height);

			if (loadedIframe) {
				locationSection.classList.add("loading");
				mapElement.appendChild(loadedIframe);
				loadedIframe.onload = () => locationSection.classList.remove("loading");
			}
		}

		if (imageElement) {
			imageElement.style.display = "block";
		}

		hideLocationCallback = hideLocation;
	};

	locationButton.onclick = showLocation;

	// Add to overlay close handlers
	overlayCloseHandlers.push(() => {
		if (hideLocationCallback) {
			hideLocationCallback();
		}
	});
};

const buildIframe = (lazyIframe: HTMLElement, width: number, height: number) => {
	const source = lazyIframe.getAttribute("src");
	if (!source) { return null; }

	const newIframe = document.createElement("iframe");
	newIframe.setAttribute("src", source);
	newIframe.setAttribute("frameborder", "0");
	newIframe.setAttribute("allowfullscreen", "allowfullscreen");
	newIframe.style.transition = "left ease 600ms, top ease 400ms, width ease 500ms, height ease 500ms";
	newIframe.width = width + "px";
	newIframe.height = height + "px";
	newIframe.classList.add("loading");
	return newIframe;
};

const destroyIframe = (lazyIframe:HTMLElement) => {
	const iframe = lazyIframe.querySelector<HTMLIFrameElement>("iframe");
	iframe?.remove();
};

const initLocations = () => {
	const locations = document.querySelectorAll<HTMLElement>('[content-type="location"]');
	if (locations.length === 0) {return;}

	locations.forEach(initLocation);
};

const cleanup = () => {
	if (hideLocationCallback) {
		hideLocationCallback();
		hideLocationCallback = null;
	}
};

overlayCloseHandlers.push(cleanup);

// Defer initialization but ensure it runs
if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', () => setTimeout(initLocations, 0));
} else {
	setTimeout(initLocations, 0);
}
